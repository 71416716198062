import axiosClient from './axiosConfig';

export const apiService = {
  async initializeGuest() {
    try {
      const response = await axiosClient.post('/guuid');
      const rmsid = response.data.data.rmsid;
      
      localStorage.setItem('msisdn', rmsid);
      
      const accessToken = response.headers.access_token;
      if (accessToken) {
        localStorage.setItem('access_token', accessToken.replace('Bearer ', ''));
      }
      
      return response.data;
    } catch (error) {
      console.error('Error initializing guest:', error);
      throw error;
    }
  },
  
  async generateToken() {
    try {
      const msisdn = localStorage.getItem('msisdn');
      const response = await axiosClient.post('/jt/gen', { msisdn });
      
      const accessToken = response.headers.authorization;
      if (accessToken) {
        localStorage.setItem('access_token', accessToken.replace('Bearer ', ''));
      }
      
      return response.data;
    } catch (error) {
      console.error('Error generating token:', error);
      throw error;
    }
  },

  async getVideos() {
    try {
      const response = await axiosClient.get('/getVideos');
      console.log('Videos response:', response.data);
      return response.data;
    } catch (error) {
      if (error.response?.status === 500) {
        await this.generateToken();
        const response = await axiosClient.get('/getVideos');
        console.log('Videos response after token refresh:', response.data);
        return response.data;
      } else {
        console.error('Error fetching videos:', error);
        throw error;
      }
    }
  },

  async likeDislike(vid) {
    try {
      const response = await axiosClient.post('/like', { vid });
      return response.data;
    } catch (error) {
      if (error.response?.status === 500) {
        await this.generateToken();
        const response = await axiosClient.post('/like', { vid });
        return response.data;
      } else {
        console.error('Error liking video:', error);
        throw error;
      }
    }
  },

  async getBookmarkedVideos() {
    try {
      const response = await axiosClient.get('/bookmarked');
      console.log('Bookmarked videos:', response.data);
      return response.data;
    } catch (error) {
      if (error.response?.status === 500) {
        await this.generateToken();
        const response = await axiosClient.get('/bookmarked');
        return response.data;
      } else {
        console.error('Error fetching bookmarked videos:', error);
        throw error;
      }
    }
  },

  async getVideoUrl(vid) {
    try {
      const videoId = btoa(vid);
      const response = await axiosClient.post('/play-saved', { "videoId": videoId });
      return response.data;
    } catch (error) {
      if (error.response?.status === 500) {
        await this.generateToken();
        const videoId = btoa(vid);
        const response = await axiosClient.post('/play-saved', { "videoId": videoId });
        return response.data;
      }
      console.error('Error fetching video:', error);
      throw error;
    }
  }
};