import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import './index.css';
import Layout from './components/Layout';
import Home from './components/Home';
import Saved from './components/Saved';
import reportWebVitals from './reportWebVitals';
import { apiService } from './services/apiService';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

const initializeApp = async () => {
  try {
    const msisdn = localStorage.getItem('msisdn');
    const token = localStorage.getItem('access_token');
    if (!msisdn || !token) {
      await apiService.initializeGuest();
    }
  } catch (error) {
    console.error('Initialization error:', error);
  }
};

initializeApp();

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/saved" element={<Saved />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
