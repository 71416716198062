import React, { useState, useEffect } from 'react';
import { Box, BottomNavigation, BottomNavigationAction, Paper, AppBar, Typography } from '@mui/material';
import { Home as HomeIcon, BookmarkBorder as SavedIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

function Layout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(location.pathname);

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <Box sx={{ 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column',
      overflow: 'hidden'  // Prevent page level scroll
    }}>
      <AppBar 
        position="static" 
        sx={{ 
          bgcolor: 'rgba(0, 0, 0, 0.5)', 
          boxShadow: 'none', 
          maxHeight: '60px'
        }}
      >
        <Typography variant="h6" sx={{ p: 2 }}>
          Smartflix
        </Typography>
      </AppBar>
      <Box sx={{ 
        flex: 1, 
        overflow: 'auto',  // Allow content scroll if needed
        display: 'flex',
        justifyContent: 'center', // Center children horizontally
        marginBottom: '60px' // Make space for bottom navigation
      }}>
        {children}
      </Box>
      <Paper 
        sx={{ 
          height: '46px',
          bgcolor: 'background.paper',
          position: 'fixed',
          bottom: 0,
          width: '100%',
          zIndex: '1000'
        }}
      >
        <BottomNavigation 
          value={value} 
          onChange={handleChange}
          sx={{ 
            height: '100%',
            '& .Mui-selected': {
              color: '#FFD700',
              '& .MuiSvgIcon-root': {
                color: '#FFD700'
              },
              '& .MuiBottomNavigationAction-label': {
                color: '#FFD700'
              }
            }
          }}
        >
          <BottomNavigationAction
            label="Home"
            value="/"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            label="Saved"
            value="/saved"
            icon={<SavedIcon />}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}

export default Layout;