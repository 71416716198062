import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Grid } from '@mui/material';
import { apiService } from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import { PlayArrow } from '@mui/icons-material';

function Saved() {
  const [loading, setLoading] = useState(false);
  const [bookmarkedVideos, setBookmarkedVideos] = useState([]);
  const [videoLoading, setVideoLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookmarkedVideos = async () => {
      try {
        setLoading(true);
        const response = await apiService.getBookmarkedVideos();
        setBookmarkedVideos(response.data);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookmarkedVideos();
  }, []);

  const handleVideoClick = async (vid) => {
    try {
      setVideoLoading(true);
      const response = await apiService.getVideoUrl(vid);
      const videoData = {
        video: response.data.video,
        description: '',
        like: 1,
        vid: vid
      };
      navigate('/', { state: { videos: [videoData] } });
    } catch (error) {
      console.error('Error loading video:', error);
    } finally {
      setVideoLoading(false);
    }
  };

  return (
    <Box sx={{ 
      height: '100%',
      width: '100%',
      p: 2,
      pb: 8, // Add padding bottom to account for bottom navigation
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none',
      'scrollbarWidth': 'none'
    }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : bookmarkedVideos.length > 0 ? (
        <Grid 
          container 
          spacing={2}
          sx={{
            mb: 4 // Add margin bottom to grid container
          }}
        >
          {bookmarkedVideos.map((video) => (
            <Grid item xs={6} sm={4} md={3} key={video.vid}>
              <Box sx={{ position: 'relative' }}>
                <Box
                  component="img"
                  src={video.poster}
                  alt="Video poster"
                  onClick={() => handleVideoClick(video.vid)}
                  sx={{
                    width: '100%',
                    borderRadius: '16px',
                    aspectRatio: '9/16',
                    objectFit: 'cover',
                    cursor: 'pointer',
                    WebkitTapHighlightColor: 'transparent',
                    transition: 'transform 0.2s ease',
                    '&:active': {
                      transform: 'scale(0.95)'
                    }
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(128, 128, 128, 0.5)',
                    borderRadius: '50%',
                    width: 40,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <PlayArrow sx={{ color: '#FFD700', fontSize: 24 }} />
                </Box>
                {videoLoading && (
                  <CircularProgress 
                    size={20}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)'
                    }}
                  />
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="h6" sx={{ textAlign: 'center', mt: 4 }}>
          No bookmarked videos found
        </Typography>
      )}
    </Box>
  );
}

export default Saved;