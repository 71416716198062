import React, { useEffect, useState, useRef } from 'react';
import { Box, CircularProgress, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual, Mousewheel } from 'swiper/modules';
import { apiService } from '../services/apiService';
import { Favorite as FavoriteIcon, PlayArrow } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/mousewheel';

function Home() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const videoRefs = useRef({});
  const [firstLoad, setFirstLoad] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [liking, setLiking] = useState(false);
  const location = useLocation();
  const [pausedVideos, setPausedVideos] = useState({});
  const [touchTimeout, setTouchTimeout] = useState(null);

  const playFirstVideo = async () => {
    const firstVideo = videoRefs.current[0];
    if (firstVideo && firstLoad) {
      try {
        await firstVideo.play();
        setFirstLoad(false);
      } catch (error) {
        console.warn('Autoplay failed:', error);
      }
    }
  };

  const handleVideoLoaded = (index) => {
    if (index === 0) {
      playFirstVideo();
    }
  };

  const fetchVideos = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const response = await apiService.getVideos();
      setVideos(prevVideos => [...prevVideos, ...response.data]);
    } catch (error) {
      console.error('Error fetching videos:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLikeClick = async (event, video, index) => {
    event.preventDefault();
    event.stopPropagation();
    
    if (liking) return;
    try {
      setLiking(true);
      await apiService.likeDislike(video.vid);
      setVideos(prevVideos => {
        const newVideos = [...prevVideos];
        newVideos[index] = {
          ...video,
          like: video.like === 1 ? 0 : 1
        };
        return newVideos;
      });
    } catch (error) {
      console.error('Error liking video:', error);
    } finally {
      setLiking(false);
    }
  };

  const handleVideoTap = async (index) => {
    const video = videoRefs.current[index];
    if (!video) return;

    if (video.paused) {
      await video.play();
      setPausedVideos(prev => ({ ...prev, [index]: false }));
    } else {
      video.pause();
      setPausedVideos(prev => ({ ...prev, [index]: true }));
    }
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const handleTouchStart = () => {
    const timeout = setTimeout(() => {
      // Optional: Add vibration feedback
      if (navigator.vibrate) {
        navigator.vibrate(50);
      }
    }, 500); // 500ms for long press
    setTouchTimeout(timeout);
  };

  const handleTouchEnd = () => {
    if (touchTimeout) {
      clearTimeout(touchTimeout);
      setTouchTimeout(null);
    }
  };

  useEffect(() => {
    const initializeVideos = async () => {
      try {
        if (location.state?.videos) {
          // Set initial saved video
          setVideos(location.state.videos);
          // Fetch regular videos to append
          const response = await apiService.getVideos();
          setVideos(prev => [...prev, ...response.data]);
        } else {
          fetchVideos();
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    initializeVideos();
    return () => {
      Object.values(videoRefs.current).forEach(video => {
        if (video) video.pause();
      });
    };
  }, [location.state]);

  const handleSlideChange = (swiper) => {
    // Pause all videos
    Object.values(videoRefs.current).forEach(video => {
      if (video) video.pause();
    });
    
    // Play active video
    const activeVideo = videoRefs.current[swiper.activeIndex];
    if (activeVideo) {
      activeVideo.play().catch(() => {});
    }
    setActiveIndex(swiper.activeIndex);

    // Load more videos when reaching last 2 slides
    if (swiper.activeIndex >= videos.length - 2) {
      fetchVideos();
    }
  };

  return (
    <Box sx={{ 
      width: '100%',
      maxWidth: '460px',
      height: '100%',
      margin: '0 auto',
      touchAction: 'manipulation'
    }}>
      <Swiper
        modules={[Virtual, Mousewheel]}
        direction="vertical"
        virtual
        mousewheel={{
          sensitivity: 1,
          thresholdDelta: 50
        }}
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={handleSlideChange}
        watchSlidesProgress
        style={{ height: '100%' }}
      >
        {videos.map((video, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            <Box sx={{ 
              height: '100%',
              width: '100%',
              position: 'relative' 
            }}>
              <Box
                onClick={() => handleVideoTap(index)}
                sx={{ height: '100%', width: '100%', position: 'relative' }}
              >
                <video
                  ref={el => videoRefs.current[index] = el}
                  src={video.video}
                  playsInline
                  loop
                  onLoadedData={() => handleVideoLoaded(index)}
                  onContextMenu={handleContextMenu}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                  onTouchCancel={handleTouchEnd}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'unset'
                  }}
                />
                {pausedVideos[index] && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: 'rgba(128, 128, 128, 0.5)',
                      borderRadius: '50%',
                      width: 60,
                      height: 60,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <PlayArrow sx={{ color: '#FFD700', fontSize: 40 }} />
                  </Box>
                )}
              </Box>
              
              {/* Description Text */}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: isMobile ? 100 : 35,
                  left: 20,
                  right: 60, // Make space for heart icon
                  zIndex: 2
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: 'white',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                    wordBreak: 'break-word'
                  }}
                >
                  {video.description}
                </Typography>
              </Box>

              {/* Heart Icon */}
              <Box
                onClick={(e) => handleLikeClick(e, video, index)}
                sx={{
                  position: 'absolute',
                  bottom: isMobile ? 100 : 50,
                  right: 20,
                  zIndex: 2,
                  cursor: 'pointer',
                  WebkitTapHighlightColor: 'transparent',
                  '&:active': {
                    transform: 'scale(0.95)',
                    transition: 'transform 0.1s'
                  },
                  userSelect: 'none'
                }}
              >
                <FavoriteIcon
                  sx={{
                    fontSize: 28,
                    color: video.like === 1 ? '#ff0000' : 'transparent',
                    stroke: 'white',
                    strokeWidth: 2,
                    transition: 'color 0.2s ease'
                  }}
                />
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 1000
          }}
        >
          <CircularProgress 
            sx={{
              color: '#FFD700'
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default Home;